import React from 'react';
import { Grid } from '@mui/material';
import { FormikProvider } from 'formik';
import CreateForm from './CreateForm';

const RegisterForm = ({
  formik,
  listValues,
  file,
  handleAddFile,
  handleRemoveFile,
  onLoad,
  onPlacesChanged,
}) => {
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
        <Grid container spacing={2}>
          <CreateForm
          formik={formik}
          listValues={listValues}
          file={file}
          handleAddFile={handleAddFile}
          handleRemoveFile={handleRemoveFile}
          onLoad={onLoad}
          onPlacesChanged={onPlacesChanged}
          />
        </Grid>
      </form>
    </FormikProvider>
  );
};

export default RegisterForm;
