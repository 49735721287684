import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const flexStyle = {
  display: 'flex',
  justifyContent:'center',
  alignItems: 'center',
  flexDirection: 'column'
}

const boxStyle = {
  ...flexStyle,
  height: '50vh'
}

const buttonStyle = {
  ...flexStyle,
}

const ShowMsgAndAction = ({ msg, subMsg, icon, handleOnClick }) => {

  return (
    <Box sx = {boxStyle}>
      <Button onClick = {handleOnClick} sx = {buttonStyle} variant = 'contained'>
        {icon}
        <Typography variant = 'h3'>{msg}</Typography>
        {
          subMsg && subMsg.length > 0 &&
          <Typography variant = 'h5'>-{subMsg}-</Typography>
        }
      </Button>
    </Box>
  );
};

export default ShowMsgAndAction;