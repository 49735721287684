import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router'; 
import { Grid } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import PageContainer from '../../components/container/PageContainer';
import TranslationButton from '../../components/Translation/TranslationButton';
import Cargas from '../marketplace/dashboard/cargas';
import LogoIcon from '../../layouts/marketplace-layout/logo/LogoIcon';

const PublicMarketplace = () => {
  const {url} = useParams()
  const selectedFilters = {}
  const { authenticatedData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
  if (!authenticatedData) {
    navigate('/auth/login');
  }
  }, [authenticatedData]);

  return (
    <PageContainer title="Marketplace" description={`Marketplace ${url}`}>
      <Grid sx={{ position: 'relative', top: '0px', left: '0px' }}>
        <LogoIcon />
      </Grid>
      <Grid sx={{ position: 'absolute', top: '0px', right: '0px' }}>
        <TranslationButton />
      </Grid>
      <Grid container sx={{ justifyContent: 'center' }}>
        <Cargas selectedFilters={selectedFilters} url={url} permitirOrdenar={false}/>
      </Grid>
    </PageContainer>
  );
};

export default PublicMarketplace;
