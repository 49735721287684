import React, { Component } from "react";
import { Marker, DirectionsRenderer } from '@react-google-maps/api';

function generateRouteInfo (res) {
  return res?.routes?.[0]?.legs?.map(l => {
    return {
      distanceText: l.distance?.text,
      distanceNumber: l.distance?.value,
      start: l.start_address,
      end: l.end_address
    }
  });
}

class DirectionRenderComponent extends Component {
    
    delayFactor = 0;

    constructor(props) {
        super(props);
        this.state = {
            directions: null,
            currentLocation: null,
        }}


  componentDidMount() {
    const startLoc = `${this.props.from.lat}, ${this.props.from.lng}`;
    const destinationLoc =`${this.props.to.lat}, ${this.props.to.lng}`;
    this.getDirections(startLoc, destinationLoc, this.props.wayPoints);
  }


  async getDirections(startLoc, destinationLoc, wayPoints = []) {
    const waypts = [];
    if (wayPoints.length > 0) {
      for (let i = 0; i < wayPoints.length; i++) {
        waypts.push({
          location: wayPoints[i],
          stopover: true,
        });
      }
    }

    const directionService = new window.google.maps.DirectionsService();

    directionService.route(
      {
        origin: startLoc,
        destination: destinationLoc,
        waypoints: waypts,
        // optimizeWaypoints: true,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
          this.props.updateRouteInfo(generateRouteInfo(result));
        } else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
          this.delayFactor += 0.2;
          // if (this.delayFactor <= 10) this.delayFactor = 0.2;
          setTimeout(() => {
            this.getDirections(startLoc, destinationLoc, wayPoints);
          }, this.delayFactor * 200);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      },
    );
  }

  render() {
    let originMarker = null;
    let destinationMarker = null;
    let wayPointsMarkers = null;

    const wayPointCount = (this.props.wayPoints && this.props.wayPoints.length) || 0;

    if (this.state.directions) {
      originMarker = (
        <Marker
          label = 'A'
          defaultIcon={null}
          position={{
            lat: parseFloat(this.props.from.lat),
            lng: parseFloat(this.props.from.lng),
          }}
        />
      );

      wayPointsMarkers = this.props.wayPoints?.map((w, wIndex) => {
        const key = `waypoint-marker-${wIndex.toString()}`;

        return (
          <Marker
            key = {key}
            label={String.fromCharCode(wIndex + 1 + 65)}
            defaultIcon={null}
            position={{
              lat: w.lat,
              lng: w.lng,
            }}
          />
        );
      }) || <></>;

      destinationMarker = (
        <Marker
          label={String.fromCharCode(wayPointCount + 1 + 65)}
          defaultIcon={null}
          position={{
            lat: parseFloat(this.props.to.lat),
            lng: parseFloat(this.props.to.lng),
          }}
        />
      );
    }
    return (
      <div>
        {originMarker}
        {wayPointsMarkers}
        {destinationMarker}
        {this.state.currentLocation && (
          <Marker
            label={this.props.index.toString()}
            position={{
              lat: this.state.currentLocation.lat,
              lng: this.state.currentLocation.lng,
            }}
          />
        )}
        {this.state.directions && (
          <DirectionsRenderer
            directions={this.state.directions}
            options={{
              polylineOptions: {
                storkeColor: this.props.storkeColor,
                strokeOpacity: 0.4,
                strokeWeight: 4,
              },
              preserveViewport: true,
              suppressMarkers: true,
              icon: { scale: 3 },
            }}
          />
        )}
      </div>
    );
  }
}

export default DirectionRenderComponent;