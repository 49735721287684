import axios from 'axios';
import { USERS_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const getUser = async (id) => {
  return axios.get(`${USERS_PATH}/${id}`);
};

export const updateUserAvatar = async (formData) => {
  return axios.post(`${USERS_PATH}/subir-foto`, formData);
};

export const updateUser = async (user) => {
  return axios.put(`${USERS_PATH}/actualizar`, user);
};

export const getAccessories = async () => {
  return axios.get(`${USERS_PATH}/listado`);
};

export const getUsersPerCompany = async () => {
  return axios.get(`${USERS_PATH}/listado-por-empresa`);
};

export const getUsers = async () => {
  return axios.get(`${USERS_PATH}/listado/simple`);
};

export const createAccessorie = async (accessorie) => {
  return axios.post(`${USERS_PATH}/guardar`, accessorie);
};

export const joinCompany = async (idCompany) => {
  return axios.put(`${USERS_PATH}/unirse-empresa?id=${idCompany}`);
};

export const deleteUser = async (id) => {
  return axios.delete(`${USERS_PATH}/eliminar?id=${id}`);
};

export const updateTokenApp = async (token) => {
  return axios.put(`${USERS_PATH}/token-app`, token);
};

export const activateTour = async (boolean, module) => {
  return axios.put(`${USERS_PATH}/tour?agregar=${boolean}&modulo=${module}`);
};

export const viewUser = async (auth) => {
  try {
    const response = await axios.get(`${USERS_PATH}/ver`, {
      headers: {
        Authorization: auth,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener los datos del usuario:', error);
    throw error;
  }
};

export const changePassword = async (id) => {
  return axios.put(`${USERS_PATH}/blanquear?id=${id}`);
};

export const getAllUsersWithSort = async (size, page, sortType1, direction) => {
  return axios.get(
    `${USERS_PATH}/listado-page?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const getAllUsersWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  direction,
) => {
  return axios.get(
    `${USERS_PATH}/listado-page?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const countUsersBackofficeDashboard = async (idCompany, filter) => {
  return axios.get(`${USERS_PATH}/contador?idEmpresa=${idCompany}&filtroTemporal=${filter}`);
};

export const createUserRegister = async (userRegister) => {
  return axios.post(`${USERS_PATH}/registro-outsourcing`, userRegister);
};

export const updatePassword = async (clave, clave1) => {
  return axios.put(`${USERS_PATH}/nuevo-usuario-contrasena?clave=${clave}&clave1=${clave1}`);
};
