import axios from 'axios';
import { DRIVER_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const getDrivers = async () => {
  return axios.get(`${DRIVER_PATH}/listado`);
};

export const getDriverListWithPagination = async (
  size = 5,
  page = 0,
  searchValue,
  vinculado = true,
) => {
  if (searchValue) {
    return axios.get(
      `${DRIVER_PATH}/listado?size=${size}&page=${page}&searchParam=${searchValue}&vinculado=${vinculado}`,
    );
  }
  return axios.get(`${DRIVER_PATH}/listado?size=${size}&page=${page}&vinculado=${vinculado}`);
};

export const getDriver = async (id) => {
  return axios.get(`${DRIVER_PATH}/ver?id=${id}`);
};

export const createDriver = async (driver) => {
  return axios.post(`${DRIVER_PATH}/guardar`, driver);
};

export const updateDriver = async (id, driver) => {
  return axios.post(`${DRIVER_PATH}/guardar/${id}`, driver);
};

export const deleteDriverById = async (id) => {
  return axios.delete(`${DRIVER_PATH}/eliminar`, { params: { id } });
};

export const getDriverCount = async (status) => {
  return axios.get(`${DRIVER_PATH}/contador?estado=${status}`);
};
export const exportDrivers = async () => {
  return axios({
    url: `${DRIVER_PATH}/exportarxlsx`,
    method: 'GET',
    responseType: 'blob',
  });
};

export const exportTemplateDrivers = async () => {
  return axios({
    url: `${DRIVER_PATH}/plantillaxlsx`,
    method: 'GET',
    responseType: 'blob',
  });
};

export const importDrivers = async (formData) => {
  return axios.post(`${DRIVER_PATH}/importarxlsx`, formData);
};

export const resendInvitation = async (id) => {
  return axios.post(`${DRIVER_PATH}/reenviar-invitacion?idChofer=${id}`);
};
