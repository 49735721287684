import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ScaleIcon from '@mui/icons-material/Scale';
import InventoryIcon from '@mui/icons-material/Inventory';
import PianoIcon from '@mui/icons-material/Piano';

import CAMION from '../assets/images/logos/camion.jpeg';
import REMOLQUE from '../assets/images/logos/remolque.jpeg';

import { ViewAttachment } from '../api/documents';

const VehicleDetailsNoForm = ({
  vehicle,
  subType,
  isHorizontal = false,
  cardSx = {},
  itemColor = 'primary.main',
  formik,
  infoSelectd
}) => {

  const mdUp = useMediaQuery((th) => th.breakpoints.up('md'));
  const [t, i18] = useTranslation();

  const [vehicleDetails, setVehicleDetails] = useState();
  const [vehicleType, setVehicleType] = useState();

  const palletKeys =
    subType &&
    ((subType.nombreCantidadPallet && Object.keys(subType.nombreCantidadPallet)) ||
      (subType.pallet && Object.keys(subType.pallet)));

  const palletsAsArray =
    palletKeys &&
    palletKeys.length > 0 &&
    palletKeys.map((p) => {
      return {
        name: p,
        amount: subType.nombreCantidadPallet ? subType.nombreCantidadPallet[p] : subType.pallet[p],
      };
    });

  const maxPallet =
    palletsAsArray &&
    palletsAsArray.reduce((acum, current) => {
      if (!acum || acum.amount < current.amount) return current;
      return acum;
    }, null);

  const verticalContainer = {
    width: '100%',
  };

  const horizontalContainer = {
    width: '100%',
    columnCount: mdUp ? 2 : 1,
  };

  useEffect(() => {
    if (formik?.values && formik?.values?.remolque !== null) {
      setVehicleDetails('Remolque');
      setVehicleType(formik?.values?.remolque.tipoRemolque);
    } else if (formik?.values && formik?.values?.vehicle !== null) {
      setVehicleDetails('Camión');
      setVehicleType(formik?.values?.vehicle.tipoCamion);
    } else {
      setVehicleDetails(vehicle.nombre);
      setVehicleType(subType);
    }
  }, [formik?.values?.remolque, formik?.values?.vehicle]);

  useEffect(() => {}, [vehicleDetails]);

  const [img, setImg] = useState();

  const getImg = (data) => {
    try {
      const a = ViewAttachment(data);
      setImg(a);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (subType) getImg(subType.img);
  }, [subType]);

  return (
    <Card variant="outlined" sx={{ boxShadow: 'none', ...cardSx }}>
      {!isHorizontal && (
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: itemColor }}>
              <InfoIcon />
            </Avatar>
          }

          title={<Typography fontSize="24px" variant='h5'>{t('VehicleCard.Title')}</Typography>}
          subheader={
            <Typography fontSize="20px">
              {i18.language === 'es'
                ? subType?.nombre
                : i18.language === 'en'
                ? subType?.nombreIngles
                : subType?.nombrePortugues}
            </Typography>
          }
          titleTypographyProps={{ variant: 'h4' }}
          subheaderTypographyProps={{ variant: 'h6' }}
        />
      )}
      <CardContent>
        <Grid container spacing={2} rowSpacing={0} p={2} pb={0}>
          <Grid
            item
            xs={12}
            sx={{
              overflowX: 'auto',
              display: 'flex',
            }}
          >
            <Grid
              item
              xs={isHorizontal ? 4 : 6}
              borderRadius="16px"
              border={1}
              overflow="hidden"
              sx={{
                borderColor: 'secondary.light',
                backgroundColor: 'rgb(247, 247, 247)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              p={1}
            >
              <img
                src={
                  subType?.img
                    ? img
                    : vehicle?.nombre === 'Camión' ||
                      vehicle?.nombre === 'Truck' ||
                      vehicle?.nombre === 'Caminhão'
                    ? CAMION
                    : REMOLQUE
                }
                onError={(e) => {
                  e.target.src =
                    vehicle.nombre === 'Camión'
                      ? CAMION
                      : REMOLQUE;
                }}
                alt="vehicle-silhouette"
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  aspectRatio: '1 / 1',
                  backgroundColor: 'rgb(247, 247, 247)',
                }}
              />
            </Grid>
            <Grid item xs={isHorizontal ? 8 : 6} p={0} display="flex" alignItems="flex-start">
              <List sx={isHorizontal ? horizontalContainer : verticalContainer}>
                {isHorizontal && (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'primary.main' }}>
                        <InfoIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={vehicle?.nombre} secondary={subType?.nombre} />
                  </ListItem>
                )}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ScaleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Fleet.My-assets.Maximum-Capacity')}
                    secondary={`${subType?.peso ? `${subType.peso} kg` : t('VehicleCard.NoValue')}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <InventoryIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Fleet.My-assets.Volume')}
                    secondary={`${
                      subType?.volumen ? `${subType?.volumen} m³` : t('VehicleCard.NoValue')
                    }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <PianoIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Fleet.My-assets.Maximum-Pallets')}
                    secondary={
                      maxPallet
                        ? `${t(`VehicleCard.${maxPallet.name}`)} x${maxPallet.amount}`
                        : `${t('VehicleCard.NoValue')}`
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VehicleDetailsNoForm;
