import React,{useState, useEffect} from 'react';
import { useRoutes } from 'react-router-dom';
import { getAuth, signInAnonymously } from "firebase/auth";
import { onMessage } from "firebase/messaging";
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './i18n/en.json';
import es from './i18n/es.json';
import pt from './i18n/pt.json';

import RTL from './layouts/fleet-layout/customizer/RTL';
import ThemeSettings from './layouts/fleet-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import RouterThird from './routes/RouterThird';
import 'react-perfect-scrollbar/dist/css/styles.css';
import useAuth from './hooks/useAuth';
import SplashScreen from './components/SplashScreen';
import { SelectdViewProvider } from './contexts/SelectdViewContext';
import { messaging } from './components/activityCenter/NotificacionPush';


const tokenIpInfo = "3a54da9a19a18e"

const App = () => {
  const routing = useRoutes(Router());
  const routingThird = useRoutes(RouterThird);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  const { isInitialized, isAuthenticated, authenticatedData } = useAuth();
  const [loading, setLoading]= useState(true)

  const [country, setCountry] = useState(null);
  const [ip, setIp] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const initSelectedLanguage = localStorage.getItem('language') || "es";
  
  useEffect(() => {
    const initI18n = async () => {
      await i18next
        .use(initReactI18next)
        .init({
          lng: initSelectedLanguage,
          interpolation: {
            escapeValue: false,
          },
          resources: {
            en: {
              translation: en,
            },
            es: {
              translation: es,
            },
            pt: {
              translation: pt,
            },
          },
        });
    };
  
    initI18n();
  }, []);

useEffect(()=>{
  if(country){
  const enteredPlatform =localStorage.getItem('accessToken')
   if (!enteredPlatform) {
    if(country === "BR"){
      localStorage.setItem("language", 'pt')
      i18next.changeLanguage('pt'); 
      return
    }
    localStorage.setItem("language", 'es')
    i18next.changeLanguage('es'); 
  } 
}
},[ip,country])

const loguearse = () => {
  const auth = getAuth(); 
  signInAnonymously(auth)
    .then(() => {
    })
    .catch((error) => {
    });
};


onMessage(messaging, message => {

  const variant = 'success'
  enqueueSnackbar(message.notification?.title, {
    variant,
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    autoHideDuration: 4000,
  });
})

useEffect(() => {
  loguearse()
}, [])


  return (
    // !loading && 
    isInitialized?  
    <SelectdViewProvider>
      <ThemeProvider theme={theme}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          {(isInitialized && isAuthenticated
           && authenticatedData?.idEmpresa && authenticatedData?.estado==="ACTIVO") ?  routing : 
          routingThird }
        </RTL>
      </ThemeProvider>
    </SelectdViewProvider>:
    <SplashScreen/>
  );
};

export default App;
