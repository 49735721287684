import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  MenuItem,
  NativeSelect,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { getCurrencies } from '../../../../../api/currency';
import useFetch from '../../../../../hooks/useFetch';
import CustomTextField from '../../../../../components/forms/custom-elements/CustomTextField';
import { guardarContraoferta } from '../../../../../api/contraoferta';
import validation from './util/validation';

const ContraOferta = ({ publicacion, vehicles, drivers, trailers, setLoading }) => {
  const [t] = useTranslation();
  const [monedas] = useFetch(getCurrencies);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      valor: publicacion.valor || '',
      idMoneda: '',
      idVehiculo: '',
      idChofer: '',
      idRemolque: '',
      incluyeIva: false,
      comentario: '',
      requerido: publicacion.pedirActivo,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const validado = validation(values, publicacion.tipoRemolque, enqueueSnackbar, t);
        setLoading(true);
        if (!validado.some((v) => v === false)) {
          await guardarContraoferta({ ...values, idCarga: publicacion.id });
          enqueueSnackbar(t('Marketplace.Actions.OnSuccessCounteroffer'), {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            autoHideDuration: 4000,
            onClick: (key) => closeSnackbar(key),
          });
          window.location.reload();
        }
        setLoading(false);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.error || t('Errors.Server'), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 4000,
          onClick: (key) => closeSnackbar(key),
        });
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <form id="Oferta" onSubmit={formik.handleSubmit}>
        <CustomTextField
          id="valor"
          label={`${t('Fleet.Home.New-So.Amount')}*`}
          value={formik.values.valor}
          onBlur={formik.handleBlur}
          type="number"
          onChange={(e) => formik.setFieldValue('valor', e.target.value)}
          variant="outlined"
          fullWidth
          disabled={publicacion.valor && publicacion.valor !== 0}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
        />

        <FormControl id="formMoneda" fullWidth sx={{ mt: 2 }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {`${t('Products.Currency')}*`}
          </Typography>
          <Select
            value={formik.values.idMoneda}
            onChange={(e) => formik.setFieldValue('idMoneda', e.target.value)}
            inputProps={{
              name: 'Moneda',
              id: 'Moneda',
            }}
          >
            {monedas &&
              monedas.map((moneda) => (
                <MenuItem key={moneda.id} value={moneda.id}>
                  {moneda.simbolo}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '100%' }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {`${t('AssetCard.Vehicle.DialogTitle')}${publicacion.pedirActivo ? '*' : ''}`}
          </Typography>
          <Autocomplete
            disablePortal
            options={vehicles}
            onChange={(event, newValue) => formik.setFieldValue('idVehiculo', newValue.id)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              formik?.values?.idVehiculo
                ? vehicles.find((option) => option.id === formik?.values?.idVehiculo)
                : null
            }
            renderInput={(params) => (
              <TextField {...params} label={t('AssetCard.Vehicle.DialogTitle')} />
            )}
          />
        </FormControl>
        <FormControl sx={{ width: '100%' }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {`${t('AssetCard.Driver.DialogTitle')}${publicacion.pedirActivo ? '*' : ''}`}
          </Typography>
          <Autocomplete
            disablePortal
            options={drivers}
            onChange={(event, newValue) => formik.setFieldValue('idChofer', newValue.id)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              formik?.values?.idChofer
                ? drivers.find((option) => option.id === formik?.values?.idChofer)
                : null
            }
            renderInput={(params) => (
              <TextField {...params} label={t('AssetCard.Driver.DialogTitle')} />
            )}
          />
        </FormControl>
        <FormControl sx={{ width: '100%' }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {`${t('AssetCard.Trailer.DialogTitle')}${
              publicacion.pedirActivo && publicacion.tipoRemolque ? '*' : ''
            }`}
          </Typography>
          <Autocomplete
            disablePortal
            options={trailers}
            onChange={(event, newValue) => formik.setFieldValue('idRemolque', newValue.id)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              formik?.values?.idRemolque
                ? trailers.find((option) => option.id === formik?.values?.idRemolque)
                : null
            }
            renderInput={(params) => (
              <TextField {...params} label={t('AssetCard.Trailer.DialogTitle')} />
            )}
          />
        </FormControl>
        <TextField
          id="comentario"
          label={`${t('Types.Comentario')}`}
          multiline
          maxRows={4}
          fullWidth
          sx={{ mt: 2 }}
          onChange={(e) => formik.setFieldValue('comentario', e.target.value)}
        />
        <FormControlLabel
          control={
            <Switch
              aria-label="Incluye Iva"
              id="switchIva"
              size="small"
              checked={formik.values.incluyeIva}
              onChange={() => formik.setFieldValue('incluyeIva', !formik.values.incluyeIva)}
            />
          }
          label={`${t('Marketplace.Actions.Includes IVA')}`}
          labelPlacement="start"
          sx={{ mt: 2, ml: 0 }}
        />
      </form>
    </FormikProvider>
  );
};

export default ContraOferta;
