import axios from 'axios';
import { VEHICLE_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const createNewVehicle = async (vehicle) => {
  return axios.post(`${VEHICLE_PATH}/guardar`, vehicle);
};

export const getVehicleList = async () => {
  return axios.get(`${VEHICLE_PATH}/listado`);
};

export const getVehicleListWithPagination = async (size = 5, page = 0, searchValue) => {
  if (searchValue) {
    return axios.get(
      `${VEHICLE_PATH}/listado?size=${size}&page=${page}&searchParam=${searchValue}`,
    );
  }
  return axios.get(`${VEHICLE_PATH}/listado?size=${size}&page=${page}`);
};

export const getVehicleById = async (id) => {
  return axios.get(`${VEHICLE_PATH}/detalle/${id}`);
};

export const getVehiclesByUserId = async (id) => {
  return axios.get(`${VEHICLE_PATH}/usuario/${id}`);
};

export const getVehiclesTypes = async () => {
  return axios.get(`${VEHICLE_PATH}/tipoVehiculo/listado`);
};

export const updateVehicle = async (vehicle) => {
  return axios.put(`${VEHICLE_PATH}/editar`, vehicle);
};

export const deleteVehicleById = async (id) => {
  return axios.delete(`${VEHICLE_PATH}/eliminar`, { params: { id } });
};

export const getVehicleCount = async (status) => {
  return axios.get(`${VEHICLE_PATH}/contador?estado=${status}`);
};
export const exportVehicle = async () => {
  return axios({
    url: `${VEHICLE_PATH}/exportarxlsx`,
    method: 'GET',
    responseType: 'blob',
  });
};
export const exportTemplateVehicle = async () => {
  return axios({
    url: `${VEHICLE_PATH}/plantillaxlsx`,
    method: 'GET',
    responseType: 'blob',
  });
};

export const importVehicle = async (formData) => {
  return axios.post(`${VEHICLE_PATH}/importarexcel`, formData);
};
