import axios from 'axios';
import { TRAILER_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const createNewTrailer = async (trailer) => {
  return axios.post(`${TRAILER_PATH}/guardar`, trailer);
};

export const getTrailerList = async () => {
  return axios.get(`${TRAILER_PATH}/listado`);
};

export const getTrailerListWithPagination = async (size = 5, page = 0, searchValue) => {
  if (searchValue) {
    return axios.get(
      `${TRAILER_PATH}/listado?size=${size}&page=${page}&searchParam=${searchValue}`,
    );
  }
  return axios.get(`${TRAILER_PATH}/listado?size=${size}&page=${page}`);
};

export const getTrailerById = async (id) => {
  return axios.get(`${TRAILER_PATH}/detalle/${id}`);
};

export const getVehiclesByUserId = async (userId) => {
  return axios.get(`${TRAILER_PATH}/transportador/${userId}`);
};

export const getTrailerTypes = async () => {
  return axios.get(`${TRAILER_PATH}/tipoRemolque/listado`);
};

export const getTrailerTypeById = async (id) => {
  return axios.get(`${TRAILER_PATH}/tipoRemolque/get/${id}`);
};

export const deleteTrailerById = async (id) => {
  return axios.delete(`${TRAILER_PATH}/eliminar`, { params: { id } });
};

export const updateTrailer = async (updatedTrailer) => {
  return axios.put(`${TRAILER_PATH}/editar`, updatedTrailer);
};

export const getTrailerCount = async (status) => {
  return axios.get(`${TRAILER_PATH}/contador?estado=${status}`);
};
export const exportTrailers = async () => {
  return axios({
    url: `${TRAILER_PATH}/exportarxlsx`,
    method: 'GET',
    responseType: 'blob',
  });
};
export const exportTemplateTrailers = async () => {
  return axios({
    url: `${TRAILER_PATH}/plantillaxlsx`,
    method: 'GET',
    responseType: 'blob',
  });
};
export const importTrailers = async (formData) => {
  return axios.post(`${TRAILER_PATH}/importarxlsx`, formData);
};
