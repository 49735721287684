
const validation = (value, remolque, enqueueSnackbar, t) => {
    const validado =[]
    if(!value.valor || value.valor === 0) {
        enqueueSnackbar(t('The value must be greater than 0'), {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            autoHideDuration: 4000,
          });
          validado.push(false)
    }
    if(!value.idMoneda){
        enqueueSnackbar(t('Travel-expenses.The currency is required'), {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            autoHideDuration: 4000,
          });
          validado.push(false)
    }
  if (value.requerido) {
    if (!value.idVehiculo) {
      enqueueSnackbar(t('Validation.vehiculoRequerido'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
      validado.push(false)
    }
    if (!value.idChofer) {
        enqueueSnackbar(t('Validation.choferRequerido'), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 4000,
        });
        validado.push(false)
      }
      if (!value.idRemolque && remolque) {
        enqueueSnackbar(t('Validation.RemolqueRequerido'), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 4000,
        });
        validado.push(false)
      }
  }
  return validado;
};

export default validation;
