import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';

const CreateFreeAccountModal = ({ open, setOpen }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Ofertar, hacer preguntas y mucho más</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para poder interactuar sobre los pedidos publicados necesitas crear una cuenta en Follow
          LSN. Además vas a poder conocer mas detalles como el tipo de chasis ideal para la carga,
          el peso, volumen, ofertas y preguntas de otros interesados.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => navigate('/auth/register')}
          autoFocus
          size="small"
        >
          <Typography fontWeight="medium">Quiero mi cuenta</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateFreeAccountModal;
