import axios from 'axios';
import { AUTH_PATH } from '../config';

export const preRegister = async (user) => {
  return axios.post(`${AUTH_PATH}/pre-registro`, user);
};

export const login = async (user) => {
  return axios.post(`${AUTH_PATH}/login`, user);
};

export const renewToken = async (expiredToken) => {
  const instance = axios.create({headers:{"Authorization":null}})
  const { data } = await instance.post(`${AUTH_PATH}/renovar-token`,expiredToken);
  return data;
};

export const forgotPassword = async (email) => {
  return axios.get(`${AUTH_PATH}/recuperar?email=${email}`);
};

export const recoverPassword = async (token, newPassword) => {
  return axios.post(`${AUTH_PATH}/cambiar-clave?t=${token}`, { clave: newPassword });
};

export const newCompanyUser = async (user) => {
  return axios.post(`${AUTH_PATH}/pre-registro-empresa-usuario`, user);
};

export const firstModule = async (tipo) => {
  return axios.get(`${AUTH_PATH}/actualizar-accesos?tipo=${tipo}`);
};